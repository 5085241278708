import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-center">

            {/* Copyrights note */}
            <div className="text-gray-400 text-sm mr-4">&copy; 2020 Mezzanine Level Company Melevco. All rights reserved.</div>

          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
