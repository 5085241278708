import React from 'react';
import LinkCustom from '../components/TransitionLinks/LinkCustom';

function Header() {

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-10 pb-5">
        <div className="flex items-center justify-center h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <LinkCustom url={"/"} effect="fade" lenght={0.3} className="block logo transition duration-500 ease-in-out transform hover:-translate-y-1">
              <svg width="200px" viewBox="0 0 500 209" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Melevco_Logotype_White" fill="#FFFFFF" fillRule="nonzero">
                    <polygon id="Path" points="445.458 161.656 436.586 143.786 429.463 143.786 442.208 168.529 442.208 185.023 448.643 185.023 448.643 168.403 461.329 143.786 454.33 143.786"></polygon>
                    <polygon id="Path" points="300.441 165.655 285.383 143.287 282.759 143.287 282.759 185.023 289.194 185.023 289.194 159.593 299.316 174.463 301.565 174.463 311.626 159.657 311.626 185.023 318.061 185.023 318.061 143.287 315.56 143.287"></polygon>
                    <path d="M218.653,149.035 C223.089,149.035 226.526,151.284 228.837,155.844 L234.024,152.908 C231.212,146.66 225.775,143.099 218.591,143.099 C207.781,143.099 200.034,151.722 200.034,164.405 C200.034,177.088 207.781,185.71 218.591,185.71 C225.775,185.71 231.274,182.149 234.024,175.901 L228.837,172.965 C226.526,177.525 223.089,179.774 218.653,179.774 C211.53,179.774 206.653,173.902 206.653,164.405 C206.657,155.033 211.53,149.035 218.653,149.035 Z" id="Path"></path>
                    <path d="M257.141,143.1 C246.331,143.1 238.584,151.535 238.584,164.406 C238.584,177.088 246.331,185.711 257.141,185.711 C268.012,185.711 275.824,177.152 275.824,164.344 C275.824,151.6 268.076,143.1 257.141,143.1 Z M257.205,179.775 C249.895,179.775 245.205,173.903 245.205,164.344 C245.205,154.844 249.893,149.036 257.205,149.036 C264.389,149.036 269.263,154.908 269.263,164.344 C269.263,173.9 264.389,179.774 257.205,179.774 L257.205,179.775 Z" id="Shape"></path>
                    <path d="M340.491,143.786 L327.119,143.786 L327.119,185.023 L333.554,185.023 L333.554,169.09 L340.491,169.09 C348.738,169.09 354.049,164.09 354.049,156.345 C354.049,148.66 348.8,143.786 340.491,143.786 Z M340.429,163.28 L333.554,163.28 L333.554,149.535 L340.429,149.535 C344.989,149.535 347.487,152.159 347.487,156.407 C347.487,160.717 344.989,163.28 340.429,163.28 Z" id="Shape"></path>
                    <path d="M368.669,143.287 L352.549,185.023 L359.359,185.023 L361.922,178.088 L377.978,178.088 L380.541,185.023 L387.352,185.023 L371.232,143.287 L368.669,143.287 Z M363.921,172.587 L369.921,155.529 L375.921,172.587 L363.921,172.587 Z" id="Shape"></path>
                    <polygon id="Path" points="417.154 171.028 395.535 143.287 392.913 143.287 392.913 185.023 399.348 185.023 399.348 157.72 421.029 185.522 423.589 185.522 423.589 143.786 417.154 143.786"></polygon>
                    <polygon id="Path" points="202.096 83.76 202.096 124.997 224.963 124.997 224.963 119.187 208.531 119.187 208.531 83.76"></polygon>
                    <polygon id="Path" points="255.142 89.57 255.142 83.76 230.775 83.76 230.775 124.997 255.829 124.997 255.829 119.187 237.21 119.187 237.21 105.94 250.768 105.94 250.768 100.316 237.21 100.316 237.21 89.57"></polygon>
                    <polygon id="Path" points="331.304 83.76 331.304 124.997 354.171 124.997 354.171 119.187 337.739 119.187 337.739 83.76"></polygon>
                    <polygon id="Path" points="286.82 83.76 276.571 111.627 266.263 83.76 259.141 83.76 275.259 125.496 277.822 125.496 293.942 83.76"></polygon>
                    <polygon id="Path" points="305.938 105.94 319.496 105.94 319.496 100.316 305.938 100.316 305.938 89.57 323.87 89.57 323.87 83.76 299.503 83.76 299.503 124.997 324.557 124.997 324.557 119.187 305.938 119.187"></polygon>
                    <polygon id="Path" points="304.625 59.16 284.632 59.16 304.501 25.795 304.501 23.734 277.385 23.734 277.385 29.544 295.13 29.544 275.198 62.909 275.198 64.97 304.625 64.97"></polygon>
                    <polygon id="Path" points="386.598 37.665 408.279 65.47 410.842 65.47 410.842 23.734 404.404 23.734 404.404 50.973 382.788 23.232 380.163 23.232 380.163 64.97 386.598 64.97"></polygon>
                    <polygon id="Path" points="337.115 59.16 317.12 59.16 336.991 25.795 336.991 23.734 309.874 23.734 309.874 29.544 327.618 29.544 307.687 62.909 307.687 64.97 337.115 64.97"></polygon>
                    <polygon id="Path" points="271.513 59.16 252.892 59.16 252.892 45.914 266.45 45.914 266.45 40.292 252.892 40.292 252.892 29.544 270.824 29.544 270.824 23.734 246.457 23.734 246.457 64.97 271.513 64.97"></polygon>
                    <path d="M349.173,58.036 L365.231,58.036 L367.794,64.97 L374.6,64.97 L358.484,23.232 L355.922,23.232 L339.8,64.97 L346.611,64.97 L349.173,58.036 Z M357.173,35.48 L363.173,52.537 L351.173,52.537 L357.173,35.48 Z" id="Shape"></path>
                    <polygon id="Path" points="459.515 50.973 437.893 23.232 435.271 23.232 435.271 64.97 441.706 64.97 441.706 37.665 463.387 65.47 465.948 65.47 465.948 23.734 459.515 23.734"></polygon>
                    <polygon id="Path" points="208.53 39.54 218.652 54.411 220.903 54.411 230.962 39.605 230.962 64.97 237.397 64.97 237.397 23.232 234.898 23.232 219.779 45.6 204.721 23.232 202.095 23.232 202.095 64.97 208.53 64.97"></polygon>
                    <polygon id="Path" points="481.381 59.16 481.381 45.914 494.941 45.914 494.941 40.292 481.381 40.292 481.381 29.544 499.313 29.544 499.313 23.734 474.946 23.734 474.946 64.97 500 64.97 500 59.16"></polygon>
                    <rect id="Rectangle" x="419.84" y="23.732" width="6.435" height="41.239"></rect>
                    <polygon id="Path" points="68.518 109.932 10.169 23.255 0 23.255 0 184.99 24.937 184.99 24.937 86.448 64.161 144.074 72.878 144.074 111.857 86.691 111.857 184.99 136.796 184.99 136.796 23.255 127.111 23.255"></polygon>
                    <rect id="Rectangle" x="167.411" y="0" width="6.274" height="208.942"></rect>
                  </g>
                </g>
              </svg>
            </LinkCustom>
          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;
