import React from "react"
import {Helmet} from 'react-helmet'
import Header from "../partials/Header";
import Footer from "../partials/Footer";



export default function Layout({ children }) {
  return (
    <div>

      <Helmet>
        <title>Mezzanine Level Company Melevco</title>
        <meta name="description" content="Mezzanine Funding to companies in Oman operating in the Oil and Gas industry" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Mezzanine Level Company Melevco" />
      </Helmet>

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {children}

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}
